<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </section>
    <section class="table__container">
      <a-table
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="pagination"
        :expandIcon="expandIcon"
        rowKey="orderSn"
        @change="onPage"
      >
        <template
          slot="expandedRowRender"
          slot-scope="record"
          v-if="record.subOrders && record.subOrders.length > 0"
        >
          <a-table
            :columns="subColumns"
            :data-source="record.subOrders"
            :pagination="false"
            :rowKey="(record, index) => index"
            class="sub__table"
          >
            <template slot="orderStatusValue" slot-scope="text">
              <span :class="text === '待审核' ? 'status--orange' : ''">
                {{ text }}
              </span>
            </template>
            <template slot="createTime" slot-scope="text, record">
              {{ record.createTime ? tsFormat(record.createTime) : " - " }}
            </template>
            <template slot="orderTypeValue">
              远期订单
            </template>
          </a-table>
        </template>
        <template slot="orderStatusValue" slot-scope="text">
          <span :class="text === '待审核' ? 'status--orange' : ''">
            {{ text }}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : " - " }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            @click="onExamine(record.orderSn)"
            v-if="record.orderStatusValue === '待审核'"
          >
            审核
          </a>
          <a
            v-if="record.orderStatusValue === '已审核'"
            @click="onDetail(record.orderSn)"
          >
            查看
          </a>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  examineOrderList,
  fetchCorporationInfoByKeyword
} from "@/services/OrderManageService";
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { removeKeepAliveCache } from "@/components/keepAlive";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: "25%"
  },
  {
    title: "买方机构",
    dataIndex: "purchaserCorporationName",
    width: "15%"
  },
  {
    title: "出厂价总额（元）",
    dataIndex: "totalFactoryPrice",
    width: "10%"
  },
  {
    title: "订单类型",
    dataIndex: "orderTypeValue",
    width: "10%"
  },
  {
    title: "审核状态",
    dataIndex: "orderStatusValue",
    scopedSlots: { customRender: "orderStatusValue" },
    width: "10%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
const subColumns = [
  {
    title: "下定月份",
    dataIndex: "installmentSn",
    width: "25%"
  },
  {
    title: "买方机构",
    dataIndex: "purchaserCorporationName",
    width: "15%"
  },
  {
    title: "出厂价总额（元）",
    dataIndex: "totalFactoryPrice",
    width: "10%"
  },
  {
    title: "订单类型",
    dataIndex: "orderTypeValue",
    scopedSlots: { customRender: "orderTypeValue" },
    width: "10%"
  },
  {
    title: "审核状态",
    dataIndex: "orderStatusValue",
    scopedSlots: { customRender: "orderStatusValue" },
    width: "10%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "商品数量",
    dataIndex: "totalPurchaseQuantity",
    width: "10%"
  }
];

export default {
  name: "PurchaseOrderExamineList",
  components: {
    Breadcrumb
  },
  data() {
    return {
      fields: [
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "买方机构",
          key: "purchaserDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "创建时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "订单类型",
          key: "orderType",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "即时订单",
              value: "INSTANT"
            },
            {
              label: "远期订单",
              value: "FORWARD"
            },
            {
              label: "租赁订单",
              value: "LEASE"
            }
          ]
        },
        {
          label: "审核状态",
          key: "isAudited",
          component: "j-select",
          defaultValue: "false",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待审核",
              value: "false"
            },
            {
              label: "已审核",
              value: "true"
            }
          ]
        }
      ],
      form: {
        isAudited: "false"
      },
      areaData: [],
      fieldNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      tblColumns,
      subColumns,
      tblData: [],
      loading: false,
      pagination: {
        showQuickJumper: true
      },
      dtFormat,
      tsFormat,
      corporationList: []
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    // 展开图标
    expandIcon(props) {
      if (props.record.orderTypeValue === "远期订单") {
        if (props.expanded) {
          return (
            <a
              style="color:black"
              onClick={e => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon type="minus-square" />
            </a>
          );
        } else {
          return (
            <a
              style="color:black"
              onClick={e => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon type="plus-square" />
            </a>
          );
        }
      }
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        factoryId: "",
        orderSn: this.form.orderSn || "",
        purchaserDistributorId: this.form.purchaserDistributorId || "",
        orderType: this.form.orderType || "",
        isAudited: this.form.isAudited || "",
        orderCategory: "PURCHASE",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      examineOrderList(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 搜索下单机构
    async onSearchCorporation(value) {
      let options = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 审核
    onExamine(orderSn) {
      this.$router.push({
        name: "PurchaseOrderExamineOperation",
        params: { orderSn: orderSn, type: "examine" }
      });
    },
    // 查看详情
    onDetail(orderSn) {
      this.$router.push({
        name: "PurchaseOrderDetailOperation",
        params: { orderSn: orderSn, type: "detail" }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.loadList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "PurchaseOrderDetailOperation") {
      this.$route.meta.keepAlive = true;
    } else {
      removeKeepAliveCache.call(this);
      this.$route.meta.keepAlive = true;
    }
    next();
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 72px !important;
  text-align: left !important;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}

.status--orange {
  color: rgba(255, 134, 0, 1);
}

.sub__table {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
</style>
